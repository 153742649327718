//FONTS, SHARED RESETS, HTML AND BODY

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

@font-face {
  font-family: 'Bazar';
  src: url('./../fonts/header/bazar/font.eot');
  src: url('./../fonts/header/bazar/font.eot?#iefix') format('embedded-opentype'),
       url('./../fonts/header/bazar/font.woff') format('woff'),
       url('./../fonts/header/bazar/font.ttf') format('truetype'),
       url('./../fonts/header/bazar/font.svg#Roboto') format('svg');
}

html {
  height: 100%;
}
body {
  //prevents body scroll - used for modal to stop background scrolling
  overflow: hidden;
  &.noScroll {
    overflow: hidden;
  }

  margin: 0;
  min-height: 100%;
  //height:100%;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2em;
}

h1,h2{
  font-family: Roboto; //Bazar;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0.5em 0;
}

button{cursor: pointer;}


//BREAKPOINTS
// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  body{
    overflow: auto;
  }
}


//force show scrollbar on ipad
// ::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 7px;
//   height: 7px;
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgb(255, 255, 255);
//   -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
// }