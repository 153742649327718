@import "./../../style/variables.scss";

//EDUCATION ITEMS
section#education #edu-items{
  > section{
    width: 48%;

    &:nth-child(2n+2){
      margin-right: 4%;
    }

    //first box is important > span width across
    &:first-child{
      width:100%;
    }
  }
}

//any lists will have vertical line rather than bullets
section#education #edu-items ul.highlights{
  padding-left:0;
  list-style-type: none;

  li{
    position: relative;
    padding-left: 1em;
    margin-bottom: 1.5em;

    &:before{
      content: "";
      display:inline-block;
      position: absolute;
      left:0;
      top:0%;
      height:100%;
      width: .3em;
      background: #fff;
    }
  }
}

//BREAKPOINTS
// Large devices (desktops, below 992px)
@media (max-width: 992px) {
  //MAIN BOXES - make 100% width
  section#education #edu-items{
    //Each box
    > section {
      width: 100%;
      margin-right: 0 !important;
    }
  }
}