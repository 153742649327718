@import "../../style/variables.scss";

section#interests{
  >p:last-of-type{
    margin-bottom: 2em;
  }

  >section{
    div.images{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //justify-content: space-around;

      >div{
        padding: 0.3em;
        background: lighten($primary-color, 10%);
        width: 15em;
        margin: 0.5em;
        height: 10em;
        border-radius: 0.5em;
        text-align: center;
        position: relative;

        img{
          border-radius: 0.2em;
          max-height: 90%;
          max-width: 90%;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
}

//BREAKPOINTS
// Large devices (desktops, 992px and below)
@media (max-width: 992px) {
  //MAIN BOXES - make 100% width
  section#interests > section div.images > div{
    width: 100%;
    height: 15em;
  }
}