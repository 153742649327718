@import "./../../style/variables.scss";


section#work-detail{

  //KEYPOINTS LIST
  ul.key-points{
    margin: 1.5em 0;
  }

  //COMPANY PROFILE

  //ROLES
  section.roles{
    > ul{
      list-style-type: none;
      padding-left:0em;

      > li{
        display: table;
        border-radius: 0.2em;
        background: lighten($primary-color, 10%);
        margin-bottom:0.6em;
        padding: 0.5em 0.7em;
      }
    }
  }

  //PROJECTS
  section.projects > section.project{
    background: lighten($primary-color, 10%);
    border-radius: 0.2em;
    padding: 0.5em 0.7em;
    margin-bottom:0.6em;
    
    h4{
      margin-top:0;
      margin-bottom:0.5em;
      display: inline-block;
      border-bottom:2px solid #fff;
      padding-bottom:0.5em;
    }

    //ACHIEVEMENTS
    section.achievements{
      width:80%;
      margin:1.5em 0;
      position: relative;
      border:2px dashed $tertiary-color; 
      background: rgba( $tertiary-color, .1 );
      border-radius: 0.2em;
      padding:1em;

      &:after{
        display: block;
        content: "";
        position: absolute;
        right: 0.2em;
        bottom:0.5em;
        width: 86px;
        height: 123px;
        background: url("./../../assets/icons/main/achievement.png") no-repeat;
        opacity: 0.7;
      }

      h5{
        background: $tertiary-color;
        display: inline-block;
        padding:0.3em 0.7em;
        margin:0;
        font-size: 100%;
        //color:$tertiary-color;

        &:before{
          display: inline-block;
          content: "";
          
        }
      }

      //list of achievements
      ul.project-achievements{
        padding-left:0;
        z-index: 100;
        margin-right: 4em;
        list-style-type: none;
        
        li{
          position: relative;
          margin-bottom: 1.5em;
          padding-left: 1em;

          &:last-child{
            margin-bottom:0;
          }

          &:before{
            content: "";
            display:inline-block;
            position: absolute;
            left:0;
            top:0%;

            height:100%;
            width: .3em;
            background: $tertiary-color;
          }
        }
      }
    }
  }
}


//BREAKPOINTS
// Extra large devices (large desktops, up to 992px)
@media (max-width: 1200px) { 
  section#work-detail section.achievements{
    width:100% !important;
  }
}
// Large devices (desktops, 992px and below)
@media (max-width: 992px) {
  //remove the thumbs up character
  section#work-detail section.projects > section.project section.achievements{
    &:after{
      content: none;
    }

    ul.project-achievements{
      margin-right:0;
    }
  }
}