@import "./../../style/variables.scss";


#breadcrumb{
  margin-bottom: 1em;
  background: $secondary-color;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  padding-right: 10%;
  display: inline-block;
  //height: 4.6em;

  >a{
    display: inline-block;
  }
  .home-link {
    position: relative;
    margin: 10px;
    height: 70px;
    width: 70px;
    background: darken($secondary-color, 10%) url("./../../assets/icons/main/home-2.svg") no-repeat 50%;
    background-size: 70%;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .home-link--spinner {
    position: absolute;
    left: -10px;
    top: -10px;
    width: 90px;
    height: 90px;
  }

  .home-link svg {
    width: 100%;
    height: 100%;
    animation: spin 10s linear infinite;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .home-link circle {
    stroke-width: 2;
    stroke-dasharray: 100, 20;
    fill: none;
    stroke: $link-color;
  }

  //back to home page (NON SPINNER)
  // > div.home-link {
  //   vertical-align: middle;
  //   display: inline-block;
  //   margin: 0.3em;

  //   a {
  //     border: 3px dashed $link-color;
  //     display: inline-block;
  //     border-radius: 50%;
  //     width: 4em;
  //     height: 4em;
  //     background: darken($secondary-color, 10%) url("./../assets/icons/main/home-2.svg") no-repeat 50%;
  //     background-size: 70%;
  //   }
  // }

  //main headings (Interests, work/date ...)
  > div.headings {
    margin-left: 1em;
    vertical-align: middle;
    display: inline-block;

    span.parent-crumb {
      color: $primary-color;
    }
    h1 {
      color: $primary-color;
      margin-top: 0;
      margin-bottom: 0;
      //font-size: 90%;
    }
  }

}


//BREAKPOINTS
// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  //BREADCRUMBS - seperate BACK btn from PAGE HEADING box
  #breadcrumb {
    background: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 0;
    display: block;
    text-align: center;

    //back to home page
    > a{
      width:100%;
      margin: 0em;
      margin-bottom: 1em;
    }
    //main headings (Interests, work/date ...)
    > div.headings {
      margin: 0 auto;
      margin-left: 0;
      background: $secondary-color;
      display: inline-block;
      padding: 0.5em;
      border-radius: 1em;
    }
  }
}
