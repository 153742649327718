@import "./../../style/variables.scss";

section#work-summary{

  #work-items{
    >section{
      display: flex;
      flex-direction: column;
      width: 48%;

      &:nth-child(even){
        margin-left: 4%;
      }

      //summary
      p.summary{
        flex-grow: 1;
      }
    }
  }

  div.readmore{
    text-align: center;
    margin-top:0.5em;
    
    a{
      width:40%;
    }
  }

  //position the skills at the very bottom of the box
  // #work-items ul#work-item-skills{
  //    width: 100%;
  // }
}


//BREAKPOINTS
// Large devices (desktops, 992px and below)
@media (max-width: 992px) {
  //MAIN BOXES - make 100% width
  section#work-summary #work-items{
    //Each main box
    > section {
      width: 100%;
      margin-left: 0 !important;
    }
  }
}