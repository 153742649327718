//APP LEVEL AND COMMON CSS
@import "./../style/variables.scss";

a{
  color: $link-color;
}

div.App{
  //display: flex;
  //flex-direction: row;
  height:100%;
}


//skill boxes 
ul.skill-boxes{
  font-size:80%;
  margin: 0.5em 0;
  padding:0;
  // list-style: none;
  // padding-left:0;

  li{
    &:last-child{
      margin-right:0em;
    }

    padding:0.2em;
    background: lighten($secondary-color, 15%);
    display: inline-block;
    margin-right:0.5em;
    margin-top:0.5em;
  }
}

//BUTTONS
//primary
button.primary-btn, a.primary-btn{
  text-transform: uppercase;
  color: $secondary-color;
  border-radius: 0.2em;
  background: $primary-color;
  border:2px solid $tertiary-color;
  padding: 0.4em 0.7em;
  font-size: 80%;
  //background: linear-gradient(to bottom, darken($secondary-color, 10%) 0%,darken($secondary-color, 20%) 100%);
}
a.primary-btn{
  text-decoration: none;
}


//MAIN ONLY
main {
  background: $primary-color;
  padding: 1em;
  margin-left: 25%;
  overflow-y: auto;
  height: 100vh;
  color: #fff;
}

//PAGE HEADING DIVIDERS
main h2 {
  padding: 0.2em;
  background: $secondary-color;
  color: $primary-color;
  border-top-left-radius: 0.2em;
}


//MAIN BOXES (work experience, education, workItems ...)
.main-boxes {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  //justify-content: space-around;
  //align-content: space-around;

  //Each main box
  > section {
    &:hover {
      background: lighten($primary-color, 20%);
    }

    //width and margin are set per usage basis
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    transition: background 0.1s;
    margin-top: 0%;
    margin-bottom: 3%;
    background: lighten($primary-color, 10%);
    padding: 0.8em;
    border-radius: 0.7em;
  }
  //date header
  > section h3 {
    margin: 0 0 0.7em 0;
  }

  //role title + work location
  // >section > ul{
  //   //font-weight: normal;
  //   //margin:0;
  // }

  //seperator between list and summary
  > section > hr {
    margin: 1em 0;
    width: 3em;
    border: 1px solid white;
  }

  //skill list boxes
  ul.skill-boxes {
    color: #000;
  }
}

//list with each item having an icon (icons set seperately in specified files)
@mixin icon() {
  margin-right: 0.3em;
  display: inline-block;
  content: "";
  width: 20px;
  height: 20px;
}

ul.icons {
  padding: 0;
  list-style: none;
  margin: 0;

  margin: 0.5em 0;

  > li {
    margin-top: 0.3em;

    &:first-child {
      margin-top: 0;
    }
  }
  > li > span {
    &:before {
      vertical-align: top;
    }
    vertical-align: top;
  }

  span.qualification {
    &:before {
      @include icon();
      background: url("./../assets/icons/main/degree.svg");
      background-size: contain;
    }
  }
  span.role {
    &:before {
      @include icon();
      background: url("./../assets/icons/main/job.svg");
      background-size: contain;
    }
  }
  span.company {
    &:before {
      @include icon();
      background: url("./../assets/icons/main/company.svg");
      background-size: contain;
    }
  }
  span.location {
    &:before {
      @include icon();
      background: url("./../assets/icons/main/location.svg");
      background-size: contain;
    }
  }
}


//BREAKPOINTS
// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  main {
    margin-left: 0;
    overflow: initial;
    height: initial;
  }
}
