@import "./../../style/variables.scss";


@mixin icon()
{
  margin-right: 0.3em;
  display: inline-block;
  content: "";
  width:20px;
  height:20px;
}

//PROFILE and common
aside#profile{
  font-size:90%;
  border-right: 3px solid #fff;
  position: fixed;
  left:0;
  padding:1.5em 2em;
  //background: #FBD8BA;
  background: rgb(252, 238, 214) url("./../../assets/background/groovepaper.png") repeat scroll 0% 0%;
  width: 25%;
  overflow-y: auto;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1, h2, h3{
    color: $primary-color;
  }
  h2{
    margin-bottom:0.2em;

    span.small{
      font-size: 50%;
      margin-bottom: -0.3em;
      display: block;
    }
  }

  h2.read-more{
    a{
      border-bottom: 2px dotted $link-color;
      color: $link-color;
      text-decoration: none;
      position: relative;
      display: block;

      &:hover{
        background: rgba( $link-color, .2 )
      }

      &:after{
        content: "";
        //text-align: center;
        //line-height: 0.8em;
        //border-radius: 80%;
        background: darken($secondary-color, 10%);
        background: url("./../../assets/icons/profile/link.svg") no-repeat 50% 50%;
        //background-size: 100% auto;
        background-size: contain;
        display: block;
        position: absolute;
        bottom:0.1em;
        right: 0;
        width:1em;
        height: 1em;
      }
    }
  }

  ul{
    padding:0;
    list-style: none;
    //margin:0;
  }

  ul.icons{
    margin: 0.5em 0;

    >li{
      margin-top:0.5em;

      &:first-child{
        margin-top:0;
      }
    }
    >li>span{
      &:before{vertical-align: top;}
      vertical-align: top;
    }
  }
}

//PROFILE PICTURE
#profile-pic{
  box-shadow: 7px 7px 3px -6px rgba(0,0,0,0.5);
  margin: 0 auto;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img{
    max-width:100%;
    //max-height: 100%;
  }
}


//ABOUT
#profile-about{
  &:after{
    content: "";
    display:block;
    width:50%;
    padding-bottom:1em;
    border-bottom: 5px solid $primary-color;
    margin:0 auto;
  }
  //text-align:justify;
  hyphens: auto;
}
//PERSON NAME
#profile-about h1{
  margin-top:0.2em;
  margin-bottom:0.2em;
  font-size: 180%;
  text-align: center;
}

//PERSONAL LIST (TITLE, DOB, EMAIL...)
ul#profile-about-personal{

  > li > span{

    &:before{
      @include icon();
    }
    &.job{
      &:before{
        background: url("./../../assets/icons/profile/job.svg");
        background-size: contain;
      }
    }
    &.degree{
      &:before{
        background: url("./../../assets/icons/profile/degree.svg");
        background-size: contain;
      }
    }
    &.email{
      &:before{
        background: url("./../../assets/icons/profile/email.svg");
        background-size: contain;
      }
    }
    &.address{
      &:before{
        background: url("./../../assets/icons/profile/address.svg");
        background-size: contain;
      }
    }
    &.age{
      &:before{
        background: url("./../../assets/icons/profile/age.svg");
        background-size: contain;
      }
    }
    &.linkedIn{
      &:before{
        background: url("./../../assets/icons/profile/linkedIn.svg");
        background-size: contain;
      }
    }
  }
}

//STRAPLINE
q#profile-about-strapline{
  &:before{
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("./../../assets/icons/profile/quote-left.svg");
    background-size: contain;
    opacity: 0.2;
    margin-right:0.2em;
  }
  &:after{
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("./../../assets/icons/profile/quote-right.svg");
    background-size: contain;
    opacity: 0.2;
    margin-left:0.2em;
  }

  font-size: 80%;
  margin:0;
}

//PROFILE SKILLS
section#profile-skills{
  
  h3{
    margin-top:0.5em;
    margin-bottom:0.1em;
  }

  >div{
    &:last-child{margin-bottom:0}
    margin-bottom:0.7em;
  }
}

//ACHIEVEMENTS
section#profile-achievements{
  ul>li>span{
    &:before{
      @include icon();
      background: url("./../../assets/icons/profile/achievement.svg");
      background-size: contain;
    }
  }
}

//INTERESTS
section#profile-interests{
  ul>li>span{
    &:before{
      @include icon();
      background: url("./../../assets/icons/profile/interest.svg");
      background-size: contain;
    }
  }
}

//SITE FOOTER (copyright, privacy etc)
#site-footer{
  font-size: 60%;
  text-align: right;
  padding:1em 0;

  // #site-footer-copyr{

  // }
  // a#site-footer-privacy{

  // }
}


//BREAKPOINTS
// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  aside#profile{
    width:100%;
    position: static;
    height: initial;
    overflow: initial;
  }

  //Center strapline
  #profile-about-strapline{
    text-align:center;
  }
}
